import React from "react";

function Plante({zoom}) {
    return (
        <svg
            id={"plante"}
            xmlns="http://www.w3.org/2000/svg"
            width={21 * zoom}
            height={71 * zoom}
            fill="none"
            viewBox="0 0 104 349"
        >
            <path
                fill="#151924"
                d="M56.312 174.584S85.291-2.597 100.582.031c15.29 2.627-44.27 174.553-44.27 174.553z"
            ></path>
            <path
                fill="#151924"
                d="M56.392 182.871s-2.23-179.49 13.3-179.57c15.53-.08-13.3 179.57-13.3 179.57z"
            ></path>
            <path
                fill="#151924"
                d="M59.112 186.69S22.802 10.863 38.012 7.917c15.21-2.947 21.1 178.773 21.1 178.773z"
            ></path>
            <path
                stroke="#151924"
                strokeMiterlimit="10"
                strokeWidth="1.991"
                d="M22.941 61.828s33.21 58.928 35.52 108.14"
            ></path>
            <path
                fill="#151924"
                d="M36.401 89.458s9.96-50.965 4.22-50.885c-5.65 0-9.47 17.28-9.47 17.28s-6.61-21.9-11.31-18.952c-4.62 3.026-3.35 20.226-3.35 20.226S2.961 46.137.251 51.155c-2.63 4.937 16.24 20.226 16.24 20.226s-11.86-3.026-12.58 2.628c-.63 5.654 32.41 15.369 32.41 15.369l.08.08z"
            ></path>
            <path
                fill="#fff"
                stroke="#151924"
                strokeWidth="4.182"
                d="M81.382 186.766c4.3 27.781 9.17 63.114 11.69 94.343h-.08l.18 2.253c2.34 30.198-1.44 45.594-7.98 53.535-6.43 7.806-15.97 8.952-27.22 9.112H54.492c-11.24-.12-20.78-1.267-27.21-9.082-6.54-7.95-10.33-23.367-7.98-53.564 2.5-31.775 7.51-68.147 11.9-96.597a2095.14 2095.14 0 015.71-35.128c.75-4.338 1.35-7.785 1.77-10.148.14-.754.25-1.399.35-1.926h34.49c.1.527.21 1.171.35 1.926.42 2.364 1.03 5.812 1.78 10.149 1.49 8.676 3.53 20.907 5.73 35.127z"
            ></path>
        </svg>
    );
}

export default Plante;
