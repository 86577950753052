import React from "react";

function Plat({zoom}) {
    return (
        <svg
            id={"plat"}
            xmlns="http://www.w3.org/2000/svg"
            width={61 * zoom}
            height={29 * zoom}
            fill="none"
            viewBox="0 0 299 146"
        >
            <path
                fill="#fff"
                stroke="#151924"
                strokeWidth="4.182"
                d="M243.288 101.556l-99.11-4.777c.7-1.02 1.56-2.256 2.54-3.654 2.83-4.036 6.7-9.405 10.84-14.751 4.14-5.357 8.53-10.64 12.4-14.53 1.95-1.95 3.71-3.494 5.2-4.52 1.6-1.094 2.52-1.338 2.9-1.315 2.04.126 2.99.37 3.4.548V58.993c0 .323 0 .818.12 1.359.28 1.263 1.08 2.325 2.52 3.128.86.483 1.75.442 2.43.275.66-.163 1.28-.484 1.83-.827 1.09-.69 2.31-1.746 3.55-2.94 1.61-1.544 3.48-3.526 5.45-5.608 1.07-1.13 2.16-2.29 3.26-3.426 3.18-3.301 6.46-6.485 9.53-8.687 1.52-1.1 2.93-1.9 4.17-2.358 1.25-.46 2.21-.53 2.95-.35 1.95.47 2.73 1.386 3.1 2.372.44 1.155.44 2.723.11 4.55-.29 1.607-.78 3.168-1.21 4.53-.05.166-.1.328-.15.486-.21.679-.43 1.41-.54 1.997-.04.267-.11.734-.02 1.228.05.265.16.669.47 1.059.33.423.79.71 1.3.826.44.1.8.037.96.002.19-.043.35-.105.45-.147.19-.085.36-.186.48-.26.25-.156.53-.362.81-.582a64.28 64.28 0 002.29-1.894c1.69-1.453 3.98-3.478 6.69-5.876.27-.244.55-.492.84-.744 6.17-5.46 14.36-12.69 22.91-19.731 8.55-7.052 17.4-13.864 24.87-18.512 3.75-2.328 7.06-4.061 9.77-5.022 2.84-1.007 4.49-.977 5.29-.587.07.038.27.143.41.757.15.672.15 1.722-.1 3.217-.5 2.973-1.91 7.053-4.04 11.956-4.23 9.764-11.11 22.267-18.44 34.648-7.32 12.364-15.04 24.539-20.93 33.627-2.95 4.543-5.44 8.313-7.19 10.945l-2.04 3.051c-.03.034-.05.068-.07.101zm-20.59-47.288v-.02.02zm-2.73-1.593l.01-.003c0 .002-.01.003-.01.003z"
            ></path>
            <path
                fill="#fff"
                stroke="#151924"
                strokeWidth="4.182"
                d="M23.267 101.099l-.09-.005a25.7 25.7 0 01-.04-.975c-.03-1.742.04-4.106.45-6.619.85-5.127 2.99-10.308 7.87-12.682 3.45-1.674 5.49-4.306 6.93-7.156.97-1.905 1.73-4.078 2.45-6.123.3-.867.6-1.71.9-2.5 1.06-2.799 2.27-5.28 4.23-7.265 1.92-1.946 4.7-3.557 9.15-4.329 5.02-.872 8.15-2.219 10.37-3.884 1.69-1.265 2.82-2.728 3.72-3.89.24-.314.47-.606.69-.866 1.02-1.232 2.14-2.296 4.48-3.1 2.45-.846 6.31-1.427 12.85-1.368 6.56.06 10.31.532 12.7 1.123 2.06.507 3.14 1.1 4.33 1.751l.48.26c1.55.835 3.24 1.594 6.42 1.96 3.08.351 7.55.337 14.72-.243 7.18-.582 12.57-1.29 16.73-1.877.73-.103 1.42-.2 2.06-.294 3.1-.444 5.3-.759 7.08-.84 2.06-.095 3.27.146 4.38.755 1.2.663 2.49 1.866 4.47 4.162 2.71 3.177 3.02 5.373 3.35 7.786.1.724.21 1.468.38 2.263.39 1.828 1.17 3.646 3.09 5.437 1.83 1.71 4.63 3.312 8.88 5.003 8.36 3.317 14.65 5.98 18.98 8.68 4.33 2.698 6.25 5.16 6.64 7.905.22 1.59.19 4.215-.06 7.396-.24 3.133-.68 6.653-1.19 9.947-.51 3.3-1.1 6.333-1.62 8.497-.19.759-.36 1.379-.51 1.852l-.93-.066c-1.38-.096-3.36-.227-5.85-.389-4.97-.322-12.01-.765-20.41-1.288-16.8-1.045-39.08-2.409-61.31-3.763-22.23-1.353-44.42-2.697-61.05-3.702l-20.09-1.214-5.63-.339zm176.9 10.921a2.248 2.248 0 000 0z"
            ></path>
            <path
                fill="#fff"
                stroke="#151924"
                strokeWidth="4.182"
                d="M148.857 66.678c0 .602-.449 1.572-2.069 2.512-1.56.9-3.831 1.529-6.431 1.529-2.61 0-4.88-.612-6.43-1.504-1.6-.921-2.07-1.888-2.07-2.537 0-.65.47-1.617 2.07-2.538 1.55-.891 3.82-1.503 6.43-1.503s4.891.612 6.441 1.503c1.6.92 2.059 1.888 2.059 2.538zM97.587 72.645c0 .808-.63 2.002-2.69 3.118-1.97 1.072-4.84 1.8-8.12 1.8-3.28 0-6.15-.728-8.13-1.8-2.05-1.116-2.68-2.31-2.68-3.118 0-.806.63-2.001 2.68-3.117 1.98-1.072 4.85-1.8 8.13-1.8 3.28 0 6.15.729 8.12 1.8 2.06 1.115 2.69 2.31 2.69 3.117zM118.038 52.269c0 .164-.17.88-1.8 1.678-1.49.735-3.71 1.248-6.29 1.248s-4.8-.513-6.29-1.248c-1.63-.798-1.81-1.514-1.81-1.678 0-.163.18-.88 1.81-1.677 1.49-.735 3.71-1.25 6.29-1.25s4.8.515 6.29 1.25c1.63.798 1.8 1.514 1.8 1.677zM281.787 99.348l-48.181 43.677H53.736L5.557 99.348h276.231z"
            ></path>
        </svg>
    );
}

export default Plat;